import Cookies from '../utils/functions/cookies';
import { domain, tokenKey, roleKey, userIdKey, authorIdKey } from '../utils/variables';

export default {
  login: ({ username, password }) => {
    const identifier = username; // strapi expects 'identifier' and not 'username'
    const request = new Request(`${domain?.strapi}/auth/local`, {
      method: 'POST',
      body: JSON.stringify({ identifier, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        Cookies.setCookie(tokenKey, response?.jwt, 1);
        Cookies.setCookie(roleKey, response?.user?.role?.name, 1);
        Cookies.setCookie(userIdKey, response?.user?.id, 1);
        Cookies.setCookie(authorIdKey, response?.user?.author?.id, 1);
      });
  },

  logout: () => {
    Cookies.deleteCookie(tokenKey);
    Cookies.deleteCookie(roleKey);
    Cookies.deleteCookie(userIdKey);
    Cookies.deleteCookie(authorIdKey);
    return Promise.resolve();
  },

  checkAuth: () => {
    return Cookies.getCookie(tokenKey) ? Promise.resolve() : Promise.reject();
  },

  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      Cookies.deleteCookie(tokenKey);
      Cookies.deleteCookie(roleKey);
      Cookies.deleteCookie(userIdKey);
      return Promise.reject();
    }
    return Promise.resolve();
  },

  getPermissions: () => {
    const role = Cookies.getCookie(roleKey)?.toLowerCase();
    return role ? Promise.resolve(role) : Promise.reject();
  },
};
