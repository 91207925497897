import {
  fetchUtils,
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
} from 'react-admin';
import sanitizeText from '../utils/functions/sanitizeText';
import { translationFields } from '../utils/variables';

const { sanitizeUrl } = require('@braintree/sanitize-url');
const get = require('lodash.get');
const set = require('lodash.set');
/**
 * Maps react-admin queries to a simple REST API
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default (
  apiUrl,
  httpClient = fetchUtils.fetchJson,
  uploadFields = [],
  language,
) => {
  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */

  const contains = (a, b) => {
    // array matches
    if (Array.isArray(b)) {
      return b.some((x) => a.indexOf(x) > -1);
    }
    // string match
    return a.indexOf(b) > -1;
  };

  const adjustQueryForStrapi = (params) => {
    /*
        params = { 
            pagination: { page: {int} , perPage: {int} }, 
            sort: { field: {string}, order: {string} }, 
            filter: {Object}, 
            target: {string}, (REFERENCE ONLY)
            id: {mixed} (REFERENCE ONLY)
        }
        */

    // Handle SORTING
    const s = params.sort;
    const sort = s.field === '' ? '_sort=updated_at:DESC' : `_sort=${s.field}:${s.order}`;

    // Handle FILTER
    const f = params.filter;
    // console.log('f: ', f);
    let filter = '';
    const keys = Object.keys(f);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < keys.length; i++) {
      // react-admin uses q filter in several components and strapi use _q
      if (keys[i].includes('_in') && f[keys[i]] && Array.isArray(f[keys[i]])) {
        // console.log('f[keys[i]]: ', f[keys[i]]);
        // eslint-disable-next-line no-plusplus
        for (let y = 0; y < f[keys[i]].length; y++) {
          const val = f[keys[i]][y];
          if (val) {
            filter += `${keys[i]}=${val}${f[keys[i]][y + 1] ? '&' : ''}`;
          }
        }
      } else if (keys[i] === 'q' && f[keys[i]] !== '') {
        if (Array.isArray(f[keys[i]])) {
          // eslint-disable-next-line no-restricted-syntax
          for (const val in f[keys[i]]) {
            if (val) {
              filter += `${filter ? '&' : ''}${keys[i]}=${f[keys[i]][val]}${
                keys[i + 1][val] ? '&' : ''
              }`;
            }
          }
        } else {
          filter += `${filter ? '&' : ''}_q=${f[keys[i]]}${keys[i + 1] ? '&' : ''}`;
        }
      } else {
        if (Array.isArray(f[keys[i]])) {
          // eslint-disable-next-line no-loop-func
          f[keys[i]].forEach((territory, index) => {
            if (territory) {
              // eslint-disable-next-line no-nested-ternary
              let nextOperator = '';
              if (index < f[keys[i]].length - 1) {
                nextOperator = '&';
              } else if (keys[i + 1]) {
                nextOperator = '&';
              }
              filter += `${filter ? '&' : ''}${keys[i]}=${territory}${nextOperator}`;
            }
          });
        }
        filter += `${filter ? '&' : ''}${keys[i]}=${f[keys[i]]}${keys[i + 1] ? '&' : ''}`;
      }
    }
    if (params.id && params.target && params.target.indexOf('_id') !== -1) {
      const target = params.target.substring(0, params.target.length - 3);
      filter += `${filter ? '&' : ''}${target}=${params.id}`;
    }

    // Handle PAGINATION
    const { page, perPage } = params.pagination;
    const start = (page - 1) * perPage;
    const limit = perPage; // for strapi the _limit params indicate the amount of elements to return in the response
    const range = `_start=${start}&_limit=${limit}`;

    return `${sort}&${range}&${filter}`;
  };

  const keyify = (obj, prefix = '') => {
    if (!obj) return [];
    return Object.keys(obj).reduce((res, el) => {
      if (Array.isArray(obj[el])) {
        const objectsInArray = obj[el].filter((ew) => typeof ew === 'object');
        if (objectsInArray.length > 0) {
          let objectKeys = [];
          objectsInArray.map((object, index) => {
            objectKeys = objectKeys.concat(keyify(object, `${prefix + el}[${index}].`));
            return '';
          });
          return [...res, ...new Set(objectKeys)];
        }
        return [...res, prefix + el];
      }
      if (typeof obj[el] === 'object' && obj[el] !== null) {
        return [...res, prefix + el, ...keyify(obj[el], `${prefix + el}.`)];
      }

      return [...res, prefix + el];
    }, []);
  };
  // Determines if there are new files to upload
  const determineUploadFieldNames = (params) => {
    if (!params.data) return [];

    // Check if the field names are mentioned in the uploadFields
    // and verify there are new files being added
    // console.log('params.data: ', params.data);
    // console.log('keyify(params.data): ', keyify(params.data));

    const requestUploadFieldNames = [];
    keyify(params.data).forEach((field) => {
      // let fieldData = params.data[field];
      // console.log('fieldData1: ', field, params.data, uploadFields);
      const tempField = field.replace(/\[(.*?)\]/, '');
      // console.log('tempField: ', tempField);
      // console.log('uploadFields: ', uploadFields);
      if (uploadFields.includes(tempField)) {
        let fieldData = get(params.data, field);
        // console.log('field:1 ', field);
        // console.log('fieldData:1 ', fieldData);
        // console.log("fieldname:", field)
        fieldData = !Array.isArray(fieldData) ? [fieldData] : fieldData;
        /* eslint-disable */
        const filteredData = fieldData.filter((f) => f && f.rawFile instanceof File);
        /* eslint-disable */
        if (filteredData.length > 0) {
          requestUploadFieldNames.push(field);
        }
      }
    });

    // Return an array of field names where new files are added
    // console.log('requestUplaodFieldNames: ', requestUplaodFieldNames);
    return requestUploadFieldNames;
  };

  // Replace reference objects with reference object IDs
  const replaceRefObjectsWithIds = (_json) => {
    if (Array.isArray(_json)) {
      let newJson = _json.map((_subJson) => {
        const subJson = _subJson;
        Object.keys(subJson).forEach((key) => {
          const fd = subJson[key]; // field data
          const referenceKeys = [];
          if (fd && (fd.id || fd._id) && !fd.mime && !fd.isComponent && !fd.__component) {
            subJson[key] = fd.id || fd._id;
          } else if (
            Array.isArray(fd) &&
            fd.length > 0 &&
            fd[0] &&
            !fd[0].mime &&
            !fd[0].isComponent &&
            !fd[0].__component
          ) {
            fd.map((item) => referenceKeys.push(item.id || item._id));
            subJson[key] = referenceKeys;
          }
        });
        return subJson;
      });
      if (Array.isArray(newJson)) {
        newJson = newJson && newJson[0];
      }
      // console.log('_newJson: ', JSON.parse(JSON.stringify(newJson)));
      return JSON.parse(JSON.stringify(newJson));
    }
    const json = _json;
    Object.keys(json).forEach((key) => {
      const fd = json[key]; // field data
      const referenceKeys = [];
      if (fd && (fd.id || fd._id) && !fd.mime && !fd.isComponent && !fd.__component) {
        json[key] = fd.id || fd._id;
      } else if (
        Array.isArray(fd) &&
        fd.length > 0 &&
        fd[0] &&
        !fd[0].mime &&
        !fd[0].isComponent &&
        !fd[0].__component
      ) {
        fd.map((item) => referenceKeys.push(item.id || item._id));
        json[key] = referenceKeys;
      }
    });
    // console.log('_json 1: ', JSON.parse(JSON.stringify(json)));
    return JSON.parse(JSON.stringify(json));
  };

  // Handles file uploading for CREATE and UPDATE types
  const handleFileUpload = (type, resource, params, uploadFieldNames) => {
    // eslint-disable-next-line camelcase
    const { created_at, updated_at, createdAt, updatedAt, ...data } = params.data;
    const id = type === UPDATE ? `/${params.id}` : '';
    let res = resource;
    if (resource.indexOf('upload/files') > -1) {
      res = resource.replace('/files', '');
    }
    const { currentLocale } = params.data;
    const url = `${apiUrl}/${res}${id}`;
    const requestMethod = type === UPDATE ? 'PUT' : 'POST';
    const formData = new FormData();
    // console.log('uploadFieldNames: ', uploadFieldNames);
    // eslint-disable-next-line no-restricted-syntax
    for (const fieldName of uploadFieldNames) {
      const fieldData = get(params.data, fieldName);
      // console.log('fieldData: ', fieldData);
      const multiple = Array.isArray(fieldData);
      let existingFileIds = multiple ? [] : null;
      if (multiple) {
        // eslint-disable-next-line no-restricted-syntax
        for (const item of fieldData) {
          // console.log('item: ', item);
          // eslint-disable-next-line no-undef
          const isNewFile = item.rawFile instanceof File;
          if (!isNewFile) {
            existingFileIds.push(item.id || item._id);
          } else {
            formData.append(`files.${fieldName}`, item.rawFile);
            if (currentLocale) {
              formData.append(`files.${fieldName}_${currentLocale}`, item.rawFile);
            }
          }
        }
        // console.log('existingFileIds: ', existingFileIds);
        // data[fieldName] = [...existingFileIds];
        set(data, fieldName, [...existingFileIds]);
      } else {
        // eslint-disable-next-line no-undef
        const isNewFile = fieldData.rawFile instanceof File;
        if (!isNewFile) {
          existingFileIds = fieldData.id || fieldData._id;
        } else if (resource.indexOf('upload/files') > -1) {
          formData.append(`${fieldName}`, fieldData.rawFile);
        } else if (isNewFile) {
          formData.append(`files.${fieldName}`, fieldData.rawFile);
          if (currentLocale) {
            formData.append(`files.${fieldName}_${currentLocale}`, fieldData.rawFile);
          }
        }
        // console.log('existingFileIds: ', existingFileIds);
        // data[fieldName] = existingFileIds;
        set(data, fieldName, existingFileIds);
      }
    }
    formData.append('data', JSON.stringify(data));
    // console.log('formData: ', formData);

    return httpClient(url, {
      method: requestMethod,
      body: formData,
    }).then((response) => ({ data: replaceRefObjectsWithIds(response.json) }));
  };

  const santizeHTMLInputs = (_json) => {
    // const jsonString = JSON.stringify(_json);
    // const sanitizeString = sanitizeText(jsonString);
    // const json = JSON.parse(sanitizeString);
    if (Array.isArray(_json)) {
      const newJson = _json.map((_subJson) => {
        const subJson = _subJson;
        Object.keys(subJson).forEach((key) => {
          const fd = subJson[key]; // field data

          if (
            fd &&
            (key === 'content' ||
              key === 'proseMirrorContent' ||
              key === 'actionMessage' ||
              key === 'fullBio' ||
              key === 'locationPreviewDetails')
          ) {
            if (fd && fd !== 'undefined' && fd !== 'null') {
              subJson[key] = sanitizeText(fd);
            } else {
              subJson[key] = null;
            }
          }
          if (fd && key === 'url') {
            if (fd && fd !== 'undefined' && fd !== 'null') {
              subJson[key] = sanitizeUrl(fd);
            } else {
              subJson[key] = null;
            }
          }
        });
        return subJson;
      });

      return newJson;
    }
    const json = _json;
    Object.keys(json).forEach((key) => {
      const fd = json[key]; // field data

      if (
        fd &&
        (key === 'content' ||
          key === 'proseMirrorContent' ||
          key === 'actionMessage' ||
          key === 'fullBio' ||
          key === 'locationPreviewDetails')
      ) {
        if (fd && fd !== 'undefined' && fd !== 'null') {
          json[key] = sanitizeText(fd);
        } else {
          json[key] = null;
        }
      }
      if (fd && key === 'url') {
        if (fd && fd !== 'undefined' && fd !== 'null') {
          json[key] = sanitizeUrl(fd);
        } else {
          json[key] = null;
        }
      }
    });

    return json;

    // return json;
  };

  const findDuplicates = (arr = [], newLanguage) => {
    // eslint-disable-next-line camelcase
    const sorted_arr = arr.slice().sort(); // You can define the comparing function here.
    // JS by default uses a crappy string compare.
    // (we use slice to clone the array so the
    // original array won't be modified)
    const results = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < sorted_arr.length; i++) {
      const str = sorted_arr[i];
      if (str.endsWith(`_${newLanguage}`)) {
        if (results.indexOf(str.split(`_${newLanguage}`)[0]) === -1) {
          results.push(str.split(`_${newLanguage}`)[0]);
        }
      }
    }
    return results;
  };

  function removeLinebreaks(_str) {
    let str = _str;
    if (str) {
      str = str.toString();
      str = str.trim();
      return str.replace(/[\r\n]+/gm, '');
    }
    return '';
  }

  const stringDotNotation = (_obj, _str = '', newLanguage = '') => {
    const str = _str.split('.');
    let obj = _obj;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < str.length; i++) {
      if (str.length - 1 === i) {
        if (Array.isArray(obj)) {
          // eslint-disable-next-line no-plusplus
          for (let j = 0; j < obj.length; j++) {
            if (obj[j]) {
              const trimLine = removeLinebreaks(obj[j][`${str[i]}_${newLanguage}`]);
              if (
                obj[j][`${str[i]}_${newLanguage}`] !== 'null' &&
                obj[j][`${str[i]}_${newLanguage}`] !== 'undefined' &&
                trimLine.length > 0 &&
                trimLine !== '<p></p>' &&
                trimLine !== '<p><br></p>'
              ) {
                obj[j][str[i]] = obj[j][`${str[i]}_${newLanguage}`] || obj[j][str[i]];
              } else {
                obj[j][str[i]] = obj[j][str[i]];
              }
            }
          }
        } else if (obj) {
          const trimLine = removeLinebreaks(obj[`${str[i]}_${newLanguage}`]);
          if (
            obj[`${str[i]}_${newLanguage}`] !== 'null' &&
            obj[`${str[i]}_${newLanguage}`] !== 'undefined' &&
            trimLine.length > 0 &&
            trimLine !== '<p></p>' &&
            trimLine !== '<p><br></p>'
          ) {
            obj[str[i]] = obj[`${str[i]}_${newLanguage}`] || obj[str[i]];
          } else {
            obj[str[i]] = obj[str[i]];
          }
        }
      }
      if (Array.isArray(obj)) {
        if (str.length - 1 !== i) {
          const arr = [];
          // eslint-disable-next-line no-plusplus
          for (let j = 0; j < obj.length; j++) {
            if (obj[j] != null) {
              if (Array.isArray(obj[j][str[i]])) {
                arr.push(...obj[j][str[i]]);
              } else {
                arr.push(obj[j][str[i]]);
              }
            }
          }
          obj = arr;
        }
      } else {
        obj = obj[str[i]];
      }
      // obj = obj[str[i]];
    }
    return obj;
  };

  const keyifyAll = (obj = {}, prefix = '') => {
    if (!obj || typeof obj !== 'object') return [];
    return Object.keys(obj).reduce((res, el) => {
      const elDisplayName = `${prefix}${el}`;
      if (Array.isArray(obj[el])) {
        const objectsInArray = obj[el].filter((ew) => typeof ew === 'object');
        if (objectsInArray.length > 0) {
          let objectKeys = [];
          objectsInArray.map((object) => {
            objectKeys = objectKeys.concat(keyifyAll(object, `${prefix + el}.`));
            return '';
          });
          return [...res, ...new Set(objectKeys)];
        }
        return [...res, elDisplayName];
      }
      if (typeof obj[el] === 'object' && obj[el] !== null) {
        if (uploadFields.includes(el)) {
          return [...res, prefix + el];
        }
        return [...res, prefix + el, ...keyifyAll(obj[el], `${prefix + el}.`)];
      }
      return [...res, elDisplayName];
    }, []);
  };

  const getAllKeys = (obj) => {
    // // console.log('Object.keys(obj): ', Object.keys(obj));
    // // console.log('stringObj: ', stringObj);

    const keys = keyifyAll(obj);

    return keys;
  };

  const languageTranslation = (obj, newLanguage = '') => {
    let keys = [];
    if (Array.isArray(obj)) {
      // eslint-disable-next-line no-plusplus
      for (let j = 0; j < obj.length; j++) {
        keys = getAllKeys(obj[j]);
        const duplicateKeys = findDuplicates(keys, newLanguage);
        // eslint-disable-next-line no-restricted-syntax
        for (const key in duplicateKeys) {
          if (key) stringDotNotation(obj[j], duplicateKeys[key], newLanguage);
        }
      }
    } else if (obj !== undefined) {
      keys = getAllKeys(obj);
      const duplicateKeys = findDuplicates(keys, newLanguage);
      // eslint-disable-next-line no-restricted-syntax
      for (const key in duplicateKeys) {
        if (key) stringDotNotation(obj, duplicateKeys[key], newLanguage);
      }
    }

    return obj;
  };

  const updateStringDotNotation = (
    _obj,
    _str = '',
    currentLocale = 'en',
    originalLocale = 'en',
    isUpdate,
  ) => {
    const str = _str.split('.');
    let obj = _obj;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < str.length; i++) {
      if (str.length - 1 === i) {
        if (Array.isArray(obj)) {
          // eslint-disable-next-line no-plusplus
          for (let j = 0; j < obj.length; j++) {
            // console.log('str[i]: ', str[i]);
            // console.log('language: ', language);
            if (obj[j] && str[i]) {
              // obj[j][str[i]]
              obj[j][`${str[i]}_${currentLocale}`] = obj[j][str[i]];
              if (isUpdate) {
                // if (str[i] === "title" || str[i] === "name") {
                //    if (obj[j][str[i]])
                //        delete obj[j][str[i]];
                // }
                if (currentLocale !== originalLocale) {
                  if (obj[j][str[i]] !== undefined) delete obj[j][str[i]];
                }
              }
            }

            // if (isUpdate && (str[i] == 'title' || str[i] == 'name')) {
            // delete obj[j][str[i]];
            // }
          }
        } else if (obj && str[i]) {
          // obj[str[i]]
          obj[`${str[i]}_${currentLocale}`] = obj[str[i]];
          if (isUpdate) {
            // if (str[i] === "title" || str[i] === "name") {
            //    if (obj[str[i]])
            //        delete obj[str[i]];
            // }
            if (currentLocale !== originalLocale) {
              if (obj[str[i]] !== undefined) delete obj[str[i]];
            }
          }
        }
      }
      if (obj && Array.isArray(obj)) {
        if (str.length - 1 !== i) {
          const arr = [];
          // eslint-disable-next-line no-plusplus
          for (let j = 0; j < obj.length; j++) {
            if (obj[j] && obj[j][str[i]]) arr.push(obj[j][str[i]]);
          }
          obj = arr;
        }
      } else if (obj && obj[str[i]]) obj = obj[str[i]];
    }
    return obj;
  };

  const updateContent = (_obj, isUpdate) => {
    // console.log('obj: ', obj);
    const obj = _obj;
    const originalLocale = obj?.languages?.[obj?.defaultLocale]?.code || 'en';
    // console.log('originalLocale: ', originalLocale);
    const currentLocale = obj?.currentLocale || 'en';
    // console.log('currentLocale: ', currentLocale);
    const keys = getAllKeys(obj);
    //console.log('keys: ', keys);
    // var duplicateKeys = findDuplicates(keys);
    // eslint-disable-next-line no-restricted-syntax
    for (const key in keys) {
      //console.log('key: ', key);
      //console.log('keys[key]: ', keys[key]);
      if (keys[key]) {
        const val = keys[key].split('.');
        if (val.length > 0) {
          if (translationFields.includes(val[val.length - 1])) {
            updateStringDotNotation(
              obj,
              keys[key],
              currentLocale,
              originalLocale,
              isUpdate,
            );
          }
        } else if (translationFields.includes(keys[key])) {
          updateStringDotNotation(
            obj,
            keys[key],
            currentLocale,
            originalLocale,
            isUpdate,
          );
        }
      }
    }
    delete obj.languages;
    // delete obj.currentLocale;
    return obj;
  };
  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} Data response
   */
  const convertHTTPResponse = (response, type, resource, params) => {
    // console.log('response: ', response);
    const { status, message } = response || {};
    let { json } = response || {};
    const { headers, total } = response;
    // console.log('json1: ', JSON.parse(JSON.stringify(json)));
    if (json) {
      languageTranslation(json, language);
      json = JSON.parse(JSON.stringify(santizeHTMLInputs(json)));
      // console.log('json2: ', JSON.parse(JSON.stringify(json)));
      switch (type) {
        case GET_ONE:
          return { data: replaceRefObjectsWithIds(json) };
        case GET_LIST:
        case GET_MANY_REFERENCE:
          return {
            data: json,
            total,
          };

        case CREATE:
          return { data: { ...params.data, id: json.id } };
        case DELETE:
          return { data: { id: null } };

        default:
          if (status === 200) {
            return { data: json };
          }
          throw new Error(message);
      }
    } else {
      throw new Error(message);
    }
  };

  const convertDataRequestToHTTP = (type, resource, params) => {
    let url = '';
    const options = {};
    switch (type) {
      case GET_LIST:
      case GET_MANY_REFERENCE:
        url = `${apiUrl}/${resource}?${adjustQueryForStrapi(params)}`;
        break;
      case GET_ONE:
        url = `${apiUrl}/${resource}/${params.id}/?_publicationState=preview`;
        break;
      case UPDATE: {
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'PUT';

        const newObj = updateContent(params.data, true);
        // Omit created_at/updated_at(RDS) and createdAt/updatedAt(Mongo) in request body
        // eslint-disable-next-line camelcase
        const { created_at, updated_at, createdAt, updatedAt, ...data } = newObj;
        options.body = JSON.stringify(data);
        break;
      }
      case CREATE: {
        const newObj = updateContent(params.data, false);
        url = `${apiUrl}/${resource}`;
        options.method = 'POST';
        options.body = JSON.stringify(newObj);
        break;
      }
      case DELETE:
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'DELETE';
        break;
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
    return { url, options };
  };

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */
  return (type, _resource, params) => {
    // console.log('resource before: ', resource);
    const resource = contains(_resource, '*') ? _resource.split('*')[0] : _resource;
    // console.log('resource after: ', resource);
    // Handle file uploading
    const uploadFieldNames = determineUploadFieldNames(params);
    // console.log('uploadFieldNames: ', uploadFieldNames);
    if (uploadFieldNames.length > 0) {
      return handleFileUpload(type, resource, params, uploadFieldNames);
    }

    // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    if (type === UPDATE_MANY) {
      return Promise.all(
        params.ids.map((id) => {
          // Omit created_at/updated_at(RDS) and createdAt/updatedAt(Mongo) in request body
          // eslint-disable-next-line camelcase
          const { created_at, updated_at, createdAt, updatedAt, ...data } = params.data;
          return httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'PUT',
            body: JSON.stringify(data),
          });
        }),
      ).then((responses) => ({
        data: responses.map((response) => response.json),
      }));
    }
    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    if (type === DELETE_MANY) {
      return Promise.all(
        params.ids.map((id) =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'DELETE',
          }),
        ),
      ).then((responses) => ({
        data: responses.map((response) => response.json),
      }));
    }
    // strapi doesn't handle filters in GET route
    if (type === GET_MANY) {
      return Promise.all(
        params.ids.map((i) =>
          httpClient(`${apiUrl}/${resource}/${i.id || i._id || i}`, {
            method: 'GET',
          }),
        ),
      ).then((responses) => {
        return {
          data: responses.map((response) => {
            // console.log('resource: ', resource);
            // console.log('response: ', response);
            let jsonResponse = response?.json;
            if (resource === 'users-permissions/roles') {
              jsonResponse = response?.json?.role;
            }
            // console.log('jsonResponse: ', jsonResponse);
            return jsonResponse;
          }),
        };
      });
    }

    const { url, options } = convertDataRequestToHTTP(type, resource, params);

    // Get total via model/count endpoint
    if (type === GET_MANY_REFERENCE || type === GET_LIST) {
      const { url: urlForCount } = convertDataRequestToHTTP(
        type,
        `${resource}/count`,
        params,
      );
      const httpRequests = [];
      httpRequests.push(httpClient(url, options));
      if (resource !== 'users-permissions/roles') {
        httpRequests.push(httpClient(urlForCount, options));
      }
      return Promise.allSettled(httpRequests).then((promises) => {
        // console.log('promises: ', promises);
        const payload = promises[0].value;
        if (resource === 'users-permissions/roles') {
          payload.json = payload.json.roles;
        }

        const response = {
          // ...promises[0],
          ...payload,
          // Add total for further use
          // total: parseInt(promises[1].json, 10),
          total:
            promises[1] && promises[1].status === 'fulfilled'
              ? parseInt(promises[1].value.json, 10)
              : 0,
        };
        return convertHTTPResponse(response, type, resource, params);
      });
    }
    return httpClient(url, options).then((response) =>
      convertHTTPResponse(response, type, resource, params),
    );
  };
};
