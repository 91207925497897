import englishMessages from 'ra-language-english';

export const messages = {
  ...englishMessages,
  app: {
    articles: 'Articles',
    ggArticles: 'GG Articles',
    ggArticleCategories: 'GG Article Categories',
    products: 'Products',
    ggProducts: 'GG Products',
    lobCategories: 'Lob Categories',
    downloads: 'Downloads',
    ggDownloads: 'GG Downloads',
    ggDownloadCategories: 'GG Download Categories',
    ggDownloadTypes: 'GG Download Types',
    employees: 'Employees',
    presenters: 'Presenters',
    ggModals: 'GG Modals',
    modals: 'Modals',
    ggPresenters: 'GG Presenters',
    ggEmployees: 'GG Employees',
    ggEmployeeTypes: 'GG Employee Types',
    ggEmployeeGroupCategories: 'GG Team Categories',
    ggEmployeeGroups: 'GG Teams',
    awards: 'Awards',
    ggAwards: 'GG Awards',
    ggAwardCategories: 'GG Award Categories',
    ggAwardTypes: 'GG Award Types',
    locations: 'Locations',
    ggLocations: 'GG Locations',
    ggLocationCategories: 'GG Location Categories',
    ggLocationTypes: 'GG Location Types',
    companies: 'Companies',
    ggCompanies: 'GG Companies',
    ggCompanyCategories: 'GG Company Categories',
    ggGarages: 'GG Garages',
    misc: 'Misc',
    ggTags: 'GG Tags',
    ggBacklinks: 'GG Backlinks',
    ggAuthors: 'GG Authors',
    territories: 'Territories',
    users: 'Users',
    faqs: 'FAQs',
    ggFaqs: 'GG FAQs',
    ggFaqCategories: 'GG FAQ Categories',
    ggFaqTypes: 'GG FAQ Types',
    events: 'Events',
    ggEvents: 'GG Events',
    ggEventTypes: 'GG Event Types',
    ggActionBars: 'GG Action Bars',
    ggHeaderSection: 'GG Headers',
    ggHeaderDropdown: 'GG Header Dropdowns',
    ggFooterSection: 'GG Footers',
    ggShareholderQueries: 'GG ShareHolder Queries',
    ggProductCategories: 'GG Product Categories',
    ggCorporateStatement: 'GG Corporate Statement',
    ggCoreValues: 'GG CoreValues',
    ggGenericContents: 'GG Generic Contents',
    ggExtraContents: 'GG Extra Contents',
    ggIcons: 'GG Icons',
    ggWizards: 'GG Wizard Listings',
    ggNavigationBars: 'GG Navigation Bars',
    ggOverrides: 'GG Overrides',
    ggPages: 'GG Pages',
    pages: 'Pages',
    layouts: 'Layouts',
    genericContents: 'Generic Contents',
    glossaries: 'Glossaries',
    ggGlossaryItems: 'GG Glossary Items',
    ggGlossaries: 'GG Glossaries',
    uploadFiles: 'Upload Files',
    c360GAEvents: 'PWA App GA Events',
    helpArticles: 'Help Articles',
    ggHelpArticles: 'GG Help Articles',
    ggHelpArticleCategories: 'GG Help Article Categories',
  },
  customActions: {
    save: 'Save',
    draft: 'Draft',
    publish: 'Publish',
    unpublish: 'Unpublish',
    saveAndShow: 'Save and Show',
    cloneToDev: 'Clone to Dev',
    cloneToTest: 'Clone to Test',
    cloneToProd: 'Clone to Prod',
  },
  components: {
    common: {
      search: 'Search',
      configuration: 'Configuration',
      language: 'Language',
      theme: {
        name: 'Theme',
        light: 'Light',
        dark: 'Dark',
      },
      proseMirrorEditor: 'Prose Mirror Editor',
      quillEditor: 'Quill Editor',
    },
    customFields: {},
    customInputs: {
      contacts: {
        value: 'Value',
        displayText: 'Display Text',
        color: 'Color',
        iconColor: 'Icon Color',
        label: 'Label',
        type: 'Type',
      },
      imageComponent: {
        alt: 'Alt',
        sourceName: 'Image Name',
        source: 'Source',
        width: 'Width',
        height: 'Height',
        layout: 'Layout',
        objectFit: 'ObjectFit',
        fit: 'Fit',
        offsetX: 'OffSetX',
        offsetY: 'OffSetY',
        quality: 'Quality',
        priority: 'Priority',
        unoptimized: 'Unoptimized',
        scale: 'Scale',
        caption: 'Caption',
        sm: 'Sm',
        lg: 'Lg',
        md: 'Md',
        xl: 'Xl',
        imageStyle: 'Image Style',
        boxStyle: 'Box Style',
        isGridEnabled: 'Grid Enabled',
        flipHorizontal: 'Flip Horizontal',
        flipVertical: 'Flip Vertical',
      },
      actionButtons: {
        actionType: 'Action Type',
        actionText: 'Action Text',
        actionUrl: 'Action Url',
        gridItemAlign: 'GridItem Align',
        buttonVariant: 'Button Variant',
        buttonSize: 'Button Size',
        buttonColor: 'Button Color',
        buttonHoverColor: 'Button Hover Color',
        download: 'Download',
        target: 'Target',
        wizard: 'Wizard',
        gg_icon: 'Icon',
        icon: 'Icon',
        hidden: 'Hidden',
        iconPosition: 'Icon Position',
        labelJustification: 'Label Justification',
        labelPositionsExtreme: 'Label Positions Extreme',
        buttonGroup: 'Button Group',
        showChevron: 'Show Chevron',
        defaultMaximize: 'Default Maximize',
        lightboxLinks: 'LightBox Links',
      },
      columnConfig: {
        columnConfig: 'Column Config',
        contentBoxColumns: 'ContentBox Columns',
        productCardColumns: 'ProductCard Columns',
        centerActionButtonColumns: 'CenterActionButton Columns',
        readMoreActionButtonColumns: 'ReadMoreActionButton Columns',
        moreActionButtonColumns: 'MoreActionButton Columns',
        topRightActionButtonColumns: 'TopRightActionButton Columns',
      },
      coverText: {
        positionX: 'PositionX',
        positionY: 'PositionY',
        text: 'Text',
        textWidth: 'Text Width',
        color: 'Color',
      },
      bannerActionItems: {
        actionType: 'Action Type',
        actionText: 'Action Text',
        actionUrl: 'Action Url',
        gridItemAlign: 'GridItem Align',
        buttonVariant: 'Button Variant',
        buttonSize: 'Button Size',
        buttonColor: 'Button Color',
        buttonHoverColor: 'Button Hover Color',
        download: 'Download',
        ggIcon: 'GG Icon',
        icon: 'Icon',
        target: 'Target',
        wizard: 'Wizard',
        fullWidth: 'FullWidth',
        lg: 'Lg',
        md: 'Md',
        sm: 'Sm',
        bgcolor: 'BgColor',
      },
      bannerStaticItem: {
        title: 'Title',
        subTitle: 'Sub Title',
        color: 'Color',
        lg: 'Lg',
        md: 'Md',
        sm: 'Sm',
      },
      navigationBar: {
        name: 'Name',
        link: 'Link',
        target: 'Target',
        icon: 'Icon',
      },
      links: {
        title: 'Title',
        href: 'Href',
        type: 'Type',
        target: 'Target',
        color: 'Color',
        hoverColor: 'Hover Color',
        hidden: 'Hidden',
        iconColor: 'Icon Color',
        icon: 'Icon',
        iconAnchor: 'Icon Anchor',
        showIcon: 'Show Icon',
        column: 'Column',
        dropdown: 'Dropdown',
      },
      linksColumns: {
        title: 'Title',
        href: 'Href',
        target: 'Target',
        description: 'Description',
        displayText: 'DisplayText',
        links: 'Links',
        hidden: 'Hidden',
        navigationBar: {
          name: 'Name',
          link: 'Link',
          icon: 'Icon',
          target: 'Target',
        },
      },
    },
    dashboard: {
      title: 'Welcome to the GG Admin Panel',
    },
    layout: {},
    login: {},
    logout: {},
    profile: {},
  },
  resources: {
    c360GAEvents: {
      itemTitle: 'PWA App GA Events: "%{title}"',
      editTitle: 'Edit PWA App GA Event: "%{title}"',
      showTitle: 'Show PWA App GA Event: "%{title}"',
      createTitle: 'Create New PWA App GA Event',
      name: 'PWA App GA Events',
      fields: {
        id: 'Id',
        title: 'Title',
        description: 'Description',
        slug: 'Slug',
        content: 'Content',
        defaultLocale: 'Default Locale',
        publishedAt: 'Published At',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
      },
      tabs: {
        content: 'Content',
        summary: 'Summary',
      },
    },
    uploadFile: {
      itemTitle: 'Upload Files: "%{title}"',
      editTitle: 'Edit Upload Files: "%{title}"',
      showTitle: 'Show Upload Files: "%{title}"',
      createTitle: 'Create New Upload Files',
      name: 'Upload Files',
      aside: {
        title: 'Upload Files',
        description: `An Upload Files is used to Upload Images and Docs into different areas in the public facing website.`,
      },
      fields: {
        id: 'Id',
        name: 'Name',
        cover: 'Image',
        defaultLocale: 'Default Locale',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
        publishedAt: 'Published At',
      },
      tabs: {
        summary: 'Summary',
      },
      action: {
        upload: 'Upload',
      },
    },
    articleCategories: {
      itemTitle: 'Article Category: "%{title}"',
      editTitle: 'Edit Article Category: "%{title}"',
      showTitle: 'Show Article Category: "%{title}"',
      createTitle: 'Create New Article Category',
      name: 'Article Categories',
      aside: {
        title: 'Article Categories',
        description: `An article category is used to group articles into different areas in the public facing website.`,
      },
      fields: {
        id: 'Id',
        name: 'Name',
        defaultLocale: 'Default Locale',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
        publishedAt: 'Published At',
      },
      tabs: {
        summary: 'Summary',
      },
    },
    ggIcons: {
      itemTitle: 'Icons: "%{title}"',
      editTitle: 'Edit Icons : "%{title}"',
      showTitle: 'Show Icons : "%{title}"',
      createTitle: 'Create New Icons ',
      name: 'Icons',
      aside: {
        title: 'Icons',
        description: `An Icons is used in different areas in the public facing website.`,
      },
      fields: {
        id: 'Id',
        name: 'Name',
        slug: 'Slug',
        description: 'Description',
        defaultLocale: 'Default Locale',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
        publishedAt: 'Published At',
      },
      tabs: {
        summary: 'Summary',
        navigationBar: 'NavigationBar ',
      },
    },
    ggNavigationbars: {
      itemTitle: 'Navigation Bar: "%{title}"',
      editTitle: 'Edit Navigation Bar : "%{title}"',
      showTitle: 'Show Navigation Bar : "%{title}"',
      createTitle: 'Create New Navigation Bar ',
      name: 'Navigation Bar',
      aside: {
        title: 'Navigation Bar',
        description: `An Navigation Bar is used to group articles into different areas in the public facing website.`,
      },
      fields: {
        id: 'Id',
        name: 'Name',
        slug: 'Slug',
        description: 'Description',
        defaultLocale: 'Default Locale',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
        publishedAt: 'Published At',
      },
      tabs: {
        summary: 'Summary',
        mainNavigationBar: 'Main Navigation Buttons',
        moreNavigationButtons: 'More Navigation Buttons',
      },
    },
    modals: {
      itemTitle: 'Modal: "%{title}"',
      editTitle: 'Edit Modal: "%{title}"',
      showTitle: 'Show Modal: "%{title}"',
      createTitle: 'Create New Modal',
      name: 'Modals',
      aside: {
        title: 'Modals',
        description: `A Modal is a popup that shows relevant content on the public website.`,
      },
      fields: {
        id: 'Id',
        title: 'Title',
        slug: 'Slug',
        variant: 'Variant',
        color: 'Color',
        defaultLocale: 'Default Locale',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
        publishedAt: 'Published At',
        authors: 'Authors',
        territories: 'Territories',
      },
      tabs: {
        summary: 'Summary',
        dynamicZone: 'Dynamic Zone',
      },
    },
    extraContents: {
      itemTitle: 'Extra Content: "%{title}"',
      editTitle: 'Edit Extra Content: "%{title}"',
      showTitle: 'Show Extra Content: "%{title}"',
      createTitle: 'Create New Extra Content',
      name: 'Extra Contents',
      aside: {
        title: 'Extra Contents',
        description: `A list of extra fields that can plugin into any collection`,
      },
      fields: {
        id: 'Id',
        title: 'Title',
        displayTitle: 'Display Title',
        subTitle: 'Sub Title',
        defaultLocale: 'Default Locale',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
        publishedAt: 'Published At',
        territories: 'Territories',
        authors: 'Authors',
        featuredImage: 'Featured Image',
        categories: 'Categories',
        tags: 'Tags',
        companies: 'Companies',
        content: 'Content',
        description: 'Description',
        actionButtons: 'Action Buttons',
        slug: 'Slug',
      },
      tabs: {
        summary: 'Summary',
        miscellaneous: 'Miscellaneous',
        content: 'Content',
        actionButtons: 'Action Buttons',
        selector: 'Tab Selector',
      },
    },
    genericContents: {
      itemTitle: 'Generic Content: "%{title}"',
      editTitle: 'Edit Generic Conten: "%{title}"',
      showTitle: 'Show Generic Content: "%{title}"',
      createTitle: 'Create New Generic Content',
      name: 'Generic Contents',
      aside: {
        title: 'Generic Contents',
        description: `A Generic Contents is a popup that shows relevant content on the public website.`,
      },
      fields: {
        id: 'Id',
        title: 'Title',
        slug: 'Slug',
        showFeatureImage: 'Show Feature Image',
        invertImagePosition: 'Invert Image Position',
        defaultLocale: 'Default Locale',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
        publishedAt: 'Published At',
        authors: 'Authors',
      },
      tabs: {
        summary: 'Summary',
        breadCrumbContent: 'BreadCrumb',
      },
    },
    articles: {
      itemTitle: 'Article: "%{title}"',
      editTitle: 'Edit Article: "%{title}"',
      showTitle: 'Show Article: "%{title}"',
      createTitle: 'Create New Article',
      name: 'Articles',
      fields: {
        id: 'Id',
        title: 'Title',
        isArchived: 'Is Archived',
        description: 'Description',
        slug: 'Slug',
        content: 'Content',
        coverImage: 'Cover Image',
        featuredImage: 'Featured Image',
        coverPositionX: 'Cover PositionX',
        coverPositionY: 'Cover PositionY',
        coverScale: 'Cover Scale',
        imageFitCover: 'Image FitCover',
        layoutCoverText: 'Layout CoverText',
        textPositionX: 'Text PositionX',
        textPositionY: 'Text PositionY',
        textWidth: 'Text Width',
        textColor: 'Text Color',
        categories: 'Categories',
        tags: 'Tags',
        backlinks: 'Backlinks',
        territories: 'Territories',
        defaultLocale: 'Default Locale',
        authors: 'Authors',
        publishedAt: 'Published At',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
        hidden: 'Hidden',
        coverText: 'Cover Text',
        proseMirrorContent: 'Prose Mirror Content',
        topActionButtons: 'Top Action Buttons',
        bottomActionButtons: 'Bottom Action Buttons',
        topActionButtonColumns: 'Top Action Button Columns',
        bottomActionButtonColumns: 'Bottom Action Button Columns',
      },
      tabs: {
        image: 'Image',
        content: 'Content',
        summary: 'Summary',
        miscellaneous: 'Miscellaneous',
        featuredImage: 'Featured Image',
        coverImage: 'Cover Image',
        coverText: 'Cover Text',
        socialMediaContent: 'Social Media',
      },
    },
    awards: {
      itemTitle: 'Award: "%{title}"',
      editTitle: 'Edit Award: "%{title}"',
      showTitle: 'Show Award: "%{title}"',
      createTitle: 'Create New Award',
      name: 'Awards',
      fields: {
        id: 'Id',
        title: 'Title',
        description: 'Description',
        slug: 'Slug',
        content: 'Content',
        file: 'File',
        cover: 'Cover Image',
        featuredImage: 'Featured Image',
        categories: 'Categories',
        tags: 'Tags',
        date: 'Award Date',
        awardType: 'Award Type',
        backlinks: 'Backlinks',
        territories: 'Territories',
        defaultLocale: 'Default Locale',
        authors: 'Authors',
        publishedAt: 'Published At',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
        companies: 'Companies',
        company: 'Company',
        hidden: 'Hidden',
      },
      tabs: {
        image: 'Image',
        content: 'Content',
        summary: 'Summary',
        miscellaneous: 'Miscellaneous',
        featuredImage: 'Featured Image',
      },
    },
    awardCategories: {
      itemTitle: 'Award Category: "%{title}"',
      editTitle: 'Edit Award Category: "%{title}"',
      showTitle: 'Show Award Category: "%{title}"',
      createTitle: 'Create New Award Category',
      name: 'Award Categories',
      aside: {
        title: 'Award Categories',
        description: `An award category is used to group awards into different areas in the public facing website.`,
      },
      fields: {
        id: 'Id',
        name: 'Name',
        defaultLocale: 'Default Locale',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
        publishedAt: 'Published At',
        awardType: 'Award Type',
        icon: 'Icon',
      },
      tabs: {
        image: 'Image',
        content: 'Content',
        summary: 'Summary',
        miscellaneous: 'Miscellaneous',
      },
    },
    backlinks: {
      itemTitle: 'Backlink: "%{title}"',
      editTitle: 'Edit Backlink: "%{title}"',
      showTitle: 'Show Backlink: "%{title}"',
      createTitle: 'Create New Backlink',
      name: 'Backlinks',
      aside: {
        title: 'Backlinks',
        description: `Backlinks are essential for your website if you are hoping to receive free
      visitors from search engines like Google, Yahoo! and Bing. A backlink is counted
      when Website A links to Website B. Search engines recognize when one website links
      to another and therefore considers that website more worthy of appearing within
      your search results. So to sum up, with the more backlinks you have pointing to
      your website, search engines will reward you with higher rankings.`,
      },
      fields: {
        id: 'Id',
        title: 'Title',
        url: 'Url',
        defaultLocale: 'Default Locale',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
        publishedAt: 'Published At',
      },
      tabs: {
        summary: 'Summary',
      },
    },
    companies: {
      itemTitle: 'Company: "%{title}"',
      editTitle: 'Edit Company: "%{title}"',
      showTitle: 'Show Company: "%{title}"',
      createTitle: 'Create New Company',
      name: 'Companies',
      fields: {
        id: 'Id',
        title: 'Title',
        name: 'Name',
        isActive: 'Is Active',
        description: 'Description',
        slug: 'Slug',
        content: 'Content',
        cover: 'Cover Image',
        categories: 'Categories',
        tags: 'Tags',
        backlinks: 'Backlinks',
        territories: 'Territories',
        defaultLocale: 'Default Locale',
        authors: 'Authors',
        publishedAt: 'Published At',
        createdAt: 'Created At',
        updatedAt: 'Last Updated At',
        downloads: 'Downloads',
        subCompanies: 'Sub Companies',
        parentCompany: 'Parent Company',
        entity: 'Entity',
        percentMembership: 'Percent MemberShip',
        hidden: 'Hidden',
        actionButtons: 'Action Buttons',
        actionType: 'Action Type',
        actionText: 'Action Text',
        actionUrl: 'Action Url',
        gridItemAlign: 'GridItem Align',
        buttonVariant: 'Button Variant',
        buttonSize: 'Button Size',
        buttonColor: 'Button Color',
        buttonHoverColor: 'Button Hover Color',
        showInOrgChart: 'Show In Org Chart',
        hasContactLocations: 'Has Contact Locations',
        hasForms: 'Has Forms',
        formsPositionNo: 'Forms Position No',
        hasFAQs: 'Has FAQs',
        faqsPositionNo: 'FAQs Position No',
        contactLocationsPositionNo: 'Contact Locations Position No',
        orgChartPositionNo: 'OrgChart Position No',
        coverText: 'Cover Text',
      },
      tabs: {
        image: 'Image',
        content: 'Content',
        summary: 'Summary',
        downloads: ' Downloads',
        miscellaneous: 'Miscellaneous',
        selector: 'Tab Selector',
        actionButtons: 'Action Buttons',
        featuredImage: 'Featured Image',
        coverImage: 'Cover Image',
        coverText: 'Cover Text',
        socialMediaContent: 'Social Media',
      },
    },
    companyCategories: {
      itemTitle: 'Company Category: "%{title}"',
      editTitle: 'Edit Company Category: "%{title}"',
      showTitle: 'Show Company Category: "%{title}"',
      createTitle: 'Create New Company Category',
      name: 'Company Categories',
      aside: {
        title: 'Company Categories',
        description: `An company category is used to group companies into different areas in the public facing website.`,
      },
      fields: {
        id: 'Id',
        name: 'Name',
        icon: 'Icon',
        defaultLocale: 'Default Locale',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
        publishedAt: 'Published At',
        companies: 'Companies',
      },
      tabs: {
        summary: 'Summary',
      },
    },
    garages: {
      itemTitle: 'Garage: "%{title}"',
      editTitle: 'Edit Garage: "%{title}"',
      showTitle: 'Show Garage: "%{title}"',
      createTitle: 'Create New Garage',
      name: 'Garages',
      fields: {
        id: 'Id',
        title: 'Title',
        description: 'Description',
        slug: 'Slug',
        content: 'Content',
        cover: 'Cover Image',
        categories: 'Categories',
        tags: 'Tags',
        backlinks: 'Backlinks',
        territories: 'Territories',
        defaultLocale: 'Default Locale',
        authors: 'Authors',
        publishedAt: 'Published At',
        createdAt: 'Created At',
        updatedAt: 'Last Updated At',
        groupByCategory: 'GroupBy Category',
        company: 'Company',
        locations: 'Locations',
        locationListHeading: 'Location List Heading',
      },
      tabs: {
        image: 'Image',
        content: 'Content',
        summary: 'Summary',
        miscellaneous: 'Miscellaneous',
      },
    },
    downloadCategories: {
      itemTitle: 'Download Category: "%{title}"',
      editTitle: 'Edit Download Category: "%{title}"',
      showTitle: 'Show Download Category: "%{title}"',
      createTitle: 'Create Download Category',
      name: 'Download Categories',
      aside: {
        title: 'Download Categories',
        description: `An download category is used to group downloads into different areas in the public facing website.`,
      },
      fields: {
        id: 'Id',
        name: 'Name',
        defaultLocale: 'Default Locale',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
        publishedAt: 'Published At',
        cardHeight: 'Card Height',
        cardWidth: 'Card Width',
        imageHeight: 'Image Height',
        imageWidth: 'Image Width',
        linksGridColumns: 'Links grid Columns',
        downloadType: 'Download Type',
        image: 'Image',
        categorySortIndex: 'Category Sort Index',
      },
      tabs: {
        summary: 'Summary',
        miscellaneous: 'Miscellaneous',
        image: 'Image',
      },
    },
    downloadTypes: {
      itemTitle: 'Download Type: "%{title}"',
      editTitle: 'Edit Download Type: "%{title}"',
      showTitle: 'Show Download Type: "%{title}"',
      createTitle: 'Create New Download Type',
      name: 'Download Types',
      aside: {
        title: 'Download Types',
        description: `An download types is used to group Download Categories together.`,
      },
      fields: {
        id: 'Id',
        name: 'Name',
        publishedAt: 'Published At',
        defaultLocale: 'Default Locale',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
      },
      tabs: {
        summary: 'Summary',
      },
    },
    employeeTypes: {
      itemTitle: 'Employee Type: "%{title}"',
      editTitle: 'Edit Employee Type: "%{title}"',
      showTitle: 'Show Employee Type: "%{title}"',
      createTitle: 'Create New Employee Type',
      name: 'Employee Types',
      aside: {
        title: 'Employee Types',
        description: `An download types is used to group Employee Type together.`,
      },
      fields: {
        id: 'Id',
        name: 'Name',
        publishedAt: 'Published At',
        defaultLocale: 'Default Locale',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
      },
      tabs: {
        summary: 'Summary',
      },
    },
    awardTypes: {
      itemTitle: 'Award Type: "%{title}"',
      editTitle: 'Edit Award Type: "%{title}"',
      showTitle: 'Show Award Type: "%{title}"',
      createTitle: 'Create New Award Type',
      name: 'Award Types',
      aside: {
        title: 'Award Types',
        description: `An award type is used to group award categories together.`,
      },
      fields: {
        id: 'Id',
        name: 'Name',
        publishedAt: 'Published At',
        defaultLocale: 'Default Locale',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
      },
      tabs: {
        summary: 'Summary',
      },
    },
    downloads: {
      itemTitle: 'Download: "%{title}"',
      editTitle: 'Edit Download: "%{title}"',
      showTitle: 'Show Download: "%{title}"',
      createTitle: 'Create New Download',
      name: 'Downloads',
      fields: {
        id: 'Id',
        title: 'Title',
        description: 'Description',
        file: 'File',
        downloadLink: 'Download Link',
        slug: 'Slug',
        content: 'Content',
        cover: 'Cover Image',
        featuredImage: 'Featured Image',
        categories: 'Categories',
        tags: 'Tags',
        backlinks: 'Backlinks',
        territories: 'Territories',
        defaultLocale: 'Default Locale',
        authors: 'Authors',
        publishedAt: 'Published At',
        createdAt: 'Created At',
        updatedAt: 'Last Updated At',
        companies: 'Companies',
        downloadYear: 'Download Year',
        downloadType: 'Download Type',
        hidden: 'Hidden',
      },
      tabs: {
        image: 'Image',
        content: 'Content',
        summary: 'Summary',
        miscellaneous: 'Miscellaneous',
        featuredImage: 'Featured Image',
        coverImage: 'Cover Image',
      },
    },
    employees: {
      itemTitle: 'Employee: "%{title}"',
      editTitle: 'Edit Employee: "%{title}"',
      showTitle: 'Show Employee: "%{title}"',
      createTitle: 'Create New Employee',
      name: 'Employees',
      fields: {
        id: 'Id',
        title: 'Title',
        name: 'Name',
        email: 'Email',
        jobTitle: 'Job Title',
        miniBio: 'MiniBio',
        fullBio: 'Full Bio',
        description: 'Description',
        employeeType: 'Employee Type',
        slug: 'Slug',
        content: 'Content',
        avatar: 'Avatar',
        avatarImage: 'Avatar Image',
        categories: 'Categories',
        tags: 'Tags',
        backlinks: 'Backlinks',
        territory: 'Territory',
        territories: 'Territories',
        defaultLocale: 'Default Locale',
        authors: 'Authors',
        publishedAt: 'Published At',
        createdAt: 'Created At',
        updatedAt: 'Last Updated At',
        gender: 'Gender',
        certificates: 'Certificates',
        hidden: 'Hidden',
        companies: 'Companies',
        phone: 'Phone',
        phoneLink: 'phoneLink',
        address: 'Address',
      },
      tabs: {
        image: 'Image',
        content: 'Content',
        fullBio: 'Full Bio',
        summary: 'Summary',
        miscellaneous: 'Miscellaneous',
        details: 'Details',
        description: 'Description',
      },
    },
    presenters: {
      itemTitle: 'Presenter: "%{title}"',
      editTitle: 'Edit Presenter: "%{title}"',
      showTitle: 'Show Presenter: "%{title}"',
      createTitle: 'Create New Presenter',
      name: 'Presenters',
      fields: {
        id: 'Id',
        title: 'Title',
        description: 'Description',
        defaultLocale: 'Default Locale',
        authors: 'Authors',
        territories: 'Territories',
        slug: 'Slug',
        content: 'Content',
        avatarImage: 'Avatar Image',
      },
      tabs: {
        image: 'Image',
        content: 'Content',
        summary: 'Summary',
      },
    },
    employeeGroupCategories: {
      itemTitle: 'Team Category: "%{title}"',
      editTitle: 'Edit Team Category: "%{title}"',
      showTitle: 'Show Team Category: "%{title}"',
      createTitle: 'Create New Team Category',
      name: 'Team Categories',
      aside: {
        title: 'Team Categories',
        description: `An employee group category is used to group Teams into different areas in the public facing website.`,
      },
      fields: {
        id: 'Id',
        title: 'Title',
        name: 'Name',
        description: 'Description',
        slug: 'Slug',
        content: 'Content',
        cover: 'Cover Image',
        categories: 'Categories',
        tags: 'Tags',
        faq: 'FAQS',
        backlinks: 'Backlinks',
        territories: 'Territories',
        defaultLocale: 'Default Locale',
        authors: 'Authors',
        publishedAt: 'Published At',
        createdAt: 'Created At',
        updatedAt: 'Last Updated At',
      },
      tabs: {
        summary: 'Summary',
      },
    },
    faqCategories: {
      itemTitle: 'FAQ Category: "%{title}"',
      editTitle: 'Edit FAQ Category: "%{title}"',
      showTitle: 'Show FAQ Category: "%{title}"',
      createTitle: 'Create FAQ Category',
      name: 'FAQ Categories',
      aside: {
        title: 'FAQ Categories',
        description: `An FAQ category is used to group FAQs into different areas in the public facing website.`,
      },
      fields: {
        id: 'Id',
        name: 'Name',
        defaultLocale: 'Default Locale',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
        publishedAt: 'Published At',
        cardHeight: 'Card Height',
        cardWidth: 'Card Width',
        imageHeight: 'Image Height',
        imageWidth: 'Image Width',
        linksGridColumns: 'Links grid Columns',
        faqType: 'FAQ Type',
        image: 'Image',
        categorySortIndex: 'Category Sort Index',
      },
      tabs: {
        summary: 'Summary',
        miscellaneous: 'Miscellaneous',
        image: 'Image',
      },
    },
    faqTypes: {
      itemTitle: 'FAQ Type: "%{title}"',
      editTitle: 'Edit FAQ Type: "%{title}"',
      showTitle: 'Show FAQ Type: "%{title}"',
      createTitle: 'Create New FAQ Type',
      name: 'FAQ Types',
      aside: {
        title: 'FAQ Types',
        description: `A FAQ types is used to group FAQ Categories together.`,
      },
      fields: {
        id: 'Id',
        name: 'Name',
        publishedAt: 'Published At',
        defaultLocale: 'Default Locale',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
      },
      tabs: {
        summary: 'Summary',
      },
    },
    faq: {
      itemTitle: 'Faq: "%{title}"',
      editTitle: 'Edit Faq: "%{title}"',
      showTitle: 'Show Faq: "%{title}"',
      createTitle: 'Create New Faq',
      name: 'Faqs',
      aside: {
        title: 'Faqs',
        description: `An location types is used to group Location Categories together.`,
      },
      fields: {
        id: 'Id',
        title: 'Title',
        defaultMaximize: 'Default Maximize',
        faq: 'Faqs',
        defaultLocale: 'Default Locale',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
        publishedAt: 'Published At',
        contactSortIndex: 'Contact Sort Index',
        backlinks: 'Backlinks',
        territories: 'Territories',
        authors: 'Authors',
        featuredImage: 'Featured Image',
        categories: 'Categories',
        tags: 'Tags',
        companies: 'Companies',
        content: 'Content',
        description: 'Description',
        proseMirrorContent: 'Prose Mirror Content',
        faqType: 'FAQ Type',
        actionButtons: 'Action Buttons',
      },
      tabs: {
        summary: 'Summary',
        miscellaneous: 'Miscellaneous',
        content: 'Content',
        featuredImage: 'Featured Image',
      },
    },
    events: {
      itemTitle: 'Event: "%{title}"',
      editTitle: 'Edit Event: "%{title}"',
      showTitle: 'Show Event: "%{title}"',
      createTitle: 'Create New Event',
      name: 'Events',
      aside: {
        title: 'Events',
        description: `An location types is used to group Location Categories together.`,
      },
      fields: {
        id: 'Id',
        title: 'Title',
        slug: 'Slug',
        featuredImage: 'Featured Image',
        detailImage: 'Detail Image',
        defaultLocale: 'Default Locale',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
        publishedAt: 'Published At',
        contactSortIndex: 'Contact Sort Index',
        authors: 'Authors',
        description: 'Description',
        content: 'Content',
        backlinks: 'Backlinks',
        territories: 'Territories',
        tags: 'Tags',
        faqs: 'FAQs',
        companies: 'Companies',
        eventDate: 'Event Date',
        startDate: 'Start Date',
        endDate: 'End Date',
        proseMirrorContent: 'Prose Mirror Content',
        eventType: 'Event Type',
        readMoreButton: 'Bottom Right Action Buttons',
        moreActionButtons: 'Bottom Left Action Buttons',
        topRightActionButtons: 'Top Right Action Buttons',
        centerActionTitle: 'Center Action Title',
        centerActionButtons: 'Center Action Buttons',
        columnConfig: 'Column Config',
        actionMessage: 'ActionMessage',
      },
      tabs: {
        summary: 'Summary',
        content: 'Content',
        miscellaneous: 'Miscellaneous',
        featuredImage: 'Featured Image',
        detailImage: 'Detail Image',
        actionButtons: 'Action Buttons',
        coverImage: 'Cover Image',
      },
    },
    eventTypes: {
      itemTitle: 'Event Type: "%{title}"',
      editTitle: 'Edit Event Type: "%{title}"',
      showTitle: 'Show Event Type: "%{title}"',
      createTitle: 'Create New Event Type',
      name: 'Event Types',
      aside: {
        title: 'Event Types',
        description: `An Event types is used to group Event Categories together.`,
      },
      fields: {
        id: 'Id',
        name: 'Name',
        publishedAt: 'Published At',
        defaultLocale: 'Default Locale',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
      },
      tabs: {
        summary: 'Summary',
      },
    },
    employeeGroups: {
      itemTitle: 'Team: "%{title}"',
      editTitle: 'Edit Team: "%{title}"',
      showTitle: 'Show Team: "%{title}"',
      createTitle: 'Create New Team',
      name: 'Teams',
      fields: {
        id: 'Id',
        title: 'Title',
        name: 'Name',
        description: 'Description',
        slug: 'Slug',
        content: 'Content',
        cover: 'Cover Image',
        category: 'Category',
        tags: 'Tags',
        backlinks: 'Backlinks',
        territories: 'Territories',
        defaultLocale: 'Default Locale',
        authors: 'Authors',
        publishedAt: 'Published At',
        createdAt: 'Created At',
        updatedAt: 'Last Updated At',
        company: 'Company',
        groupByCategory: 'Group By Category',
        employee: 'Employee',
        jobTitle: 'Job Title',
        position: 'Position',
        members: 'Members',
        hideBio: 'Hide Bio',
      },
      tabs: {
        image: 'Image',
        content: 'Content',
        summary: 'Summary',
        miscellaneous: 'Miscellaneous',
        details: 'Details',
        tabSelector: 'Tab Selector',
        members: 'Members',
      },
    },
    lobCategories: {
      itemTitle: 'Lob Category: "%{title}"',
      editTitle: 'Edit Lob Category: "%{title}"',
      showTitle: 'Show Lob Category: "%{title}"',
      createTitle: 'Create New Lob Category',
      name: 'Lob Categories',
      aside: {
        title: 'Lob Categories',
        description: `A lob category is used to group products into different areas in the public facing website.`,
      },
      fields: {
        id: 'Id',
        title: 'Title',
        description: 'Description',
        lob: 'Lob',
        materialIcon: 'Material Icon',
        defaultLocale: 'Default Locale',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
        publishedAt: 'Published At',
      },
      tabs: {
        summary: 'Summary',
      },
    },
    locationCategories: {
      itemTitle: 'Location Category: "%{title}"',
      editTitle: 'Edit Location Category: "%{title}"',
      showTitle: 'Show Location Category: "%{title}"',
      createTitle: 'Create New Location Category',
      name: 'Location Categories',
      aside: {
        title: 'Location Categories',
        description: `A location category is used to group locations into different areas in the public facing website.`,
      },
      fields: {
        id: 'Id',
        title: 'Title',
        name: 'Name',
        slug: 'Slug',
        defaultLocale: 'Default Locale',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
        description: 'Description',
        locations: 'Locations',
        locationType: 'Location Type',
        publishedAt: 'Published At',
      },
      tabs: {
        summary: 'Summary',
      },
    },
    locationTypes: {
      itemTitle: 'Location Type: "%{title}"',
      editTitle: 'Edit Location Type: "%{title}"',
      showTitle: 'Show Location Type: "%{title}"',
      createTitle: 'Create New Location Type',
      name: 'Location Types',
      aside: {
        title: 'Location Types',
        description: `An location types is used to group Location Categories together.`,
      },
      fields: {
        id: 'Id',
        name: 'Name',
        defaultLocale: 'Default Locale',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
        publishedAt: 'Published At',
        contactSortIndex: 'Contact Sort Index',
      },
      tabs: {
        summary: 'Summary',
      },
    },
    locations: {
      itemTitle: 'Location: "%{title}"',
      editTitle: 'Edit Location: "%{title}"',
      showTitle: 'Show Location: "%{title}"',
      createTitle: 'Create New Location',
      name: 'Locations',
      fields: {
        id: 'Id',
        title: 'Title',
        description: 'Description',
        isLatLngEmpty: 'Empty Lat Lng',
        slug: 'Slug',
        content: 'Content',
        cover: 'Cover Image',
        categories: 'Categories',
        tags: 'Tags',
        backlinks: 'Backlinks',
        territory: 'Territory',
        territories: 'Territories',
        defaultLocale: 'Default Locale',
        locationType: 'Location Type',
        authors: 'Authors',
        publishedAt: 'Published At',
        createdAt: 'Created At',
        updatedAt: 'Last Updated At',
        email: 'Email',
        phone: 'Phone',
        address: 'Address',
        latitude: 'Latitude',
        latitude_gte: 'Latitude GTE',
        latitude_lte: 'Latitude LTE',
        longitude: 'Longitude',
        longitude_gte: 'Longitude GTE',
        longitude_lte: 'Longitude LTE',
        hidden: 'Hidden',
        number: 'Number',
        company: 'Company',
        locationPreviewDetails: 'RichText Preview',
        contacts: 'Contacts',
        actionButtons: 'Action Buttons',
      },
      tabs: {
        image: 'Image',
        content: 'Content',
        summary: 'Summary',
        miscellaneous: 'Miscellaneous',
        locationPreviewDetails: 'RichText Preview',
        contacts: 'Contacts',
        actionButtons: 'Action Buttons',
      },
    },
    products: {
      itemTitle: 'Product: "%{title}"',
      editTitle: 'Edit Product: "%{title}"',
      showTitle: 'Show Product: "%{title}"',
      createTitle: 'Create New Product',
      name: 'Products',
      fields: {
        id: 'Id',
        positionNo: 'Position No.',
        title: 'Title',
        description: 'Description',
        slug: 'Slug',
        content: 'Content',
        cover: 'Cover Image',
        coverPositionX: 'Cover PositionX',
        coverPositionY: 'Cover PositionY',
        coverScale: 'Cover Scale',
        imageFitCover: 'Image Fit Cover',
        layoutCoverText: 'Layout Cover Text',
        textPositionX: 'Text PositionX',
        textPositionY: 'Text PositionY',
        textWidth: 'Text Width',
        textColor: 'Text Color',
        featured: 'Featured Image',
        categories: 'Categories',
        modalContent: 'Modal Content',
        productCategory: 'Product Category',
        tags: 'Tags',
        backlinks: 'Backlinks',
        territories: 'Territories',
        defaultLocale: 'Default Locale',
        authors: 'Authors',
        publishedAt: 'Published At',
        createdAt: 'Created At',
        updatedAt: 'Last Updated At',
        lob: 'Lob-Categories',
        buyOnlineWizard: 'Buy Online Wizard',
        quoteWizard: 'Quote Wizard',
        calculatorWizard: 'Calculator Wizard',
        contactWizard: 'Contact Wizard',
        subTitle: 'Sub Title',
        downloads: 'Downloads',
        subProducts: 'Sub Products',
        childProducts: 'Child Products',
        parentProducts: 'Parent Products',
        sectionTitle: 'Section Title',
        actionButton: 'Action Button',
        actionButtons: 'Card Action Buttons',
        readMoreButton: 'Bottom Right Action Buttons',
        moreActionButtons: 'Bottom Left Action Buttons',
        topRightActionButtons: 'Top Right Action Buttons',
        centerActionTitle: 'Center Action Title',
        centerActionButtons: 'Center Action Buttons',
        columnConfig: 'Column Config',
        isC360Product: 'IsC360Product',
        isProductType: 'IsProductType',
        products: 'Products',
        hidden: 'hidden',
        icon: 'Icon',
        section: 'Section',
        hideProductOnWebsite: 'Hide Product on Website',
        hideDescriptionOnPWA: 'Hide Description On PWA',
        hideDescriptionOnWebsite: 'Hide Description On Website',
        hideContentOnWebsite: 'Hide Content On Website',
        hideContentOnPWA: 'Hide Content On PWA',
        children: 'Children',
        hasParent: 'Has Parent',
        hasModal: 'Has Modal',
        hideActionMessageOnPWA: 'Hide ActionMessage On PWA',
        hideActionMessageOnWebsite: 'Hide ActionMessage On Website',
        actionMessage: 'ActionMessage',
        c360Layout: 'C360 Layout',
        coverText: 'Cover Text',
        socialMediaContent: 'Social Media Content',
        proseMirrorContent: 'Prose Mirror Content',
        productCode: 'Product Code',
        isMarketed: 'Is Marketed',
      },
      tabs: {
        image: 'Image',
        details: 'Details',
        description: 'Description',
        reviews: 'Reviews',
        mapping: 'Mapping',
        miscellaneous: 'Miscellaneous',
        content: 'content',
        actionButtons: 'Action Buttons',
        featuredImage: 'Featured Image',
        coverImage: 'Cover Image',
        readMoreButton: 'Read More Button',
        featuredImagePwa: 'Featured Image_Pwa',
        actionMessage: 'Action Message',
        coverText: 'Cover Text',
        socialMediaContent: 'Social Media',
      },
    },
    tags: {
      itemTitle: 'Tag: "%{title}"',
      editTitle: 'Edit Tag: "%{title}"',
      showTitle: 'Show Tag: "%{title}"',
      createTitle: 'Create New Tag',
      name: 'Tags',
      aside: {
        title: 'Tags',
        description: `A tag is used to grouping different content together and to be able to showcase 
      related content together on the public facing website.`,
      },
      fields: {
        id: 'Id',
        name: 'Name',
        title: 'Title',
        value: 'Value',
        icon: 'Icon',
        color: 'Color',
        defaultLocale: 'Default Locale',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
        publishedAt: 'Published At',
      },
      tabs: {
        summary: 'Summary',
      },
    },
    authors: {
      itemTitle: 'Author: "%{title}"',
      editTitle: 'Edit Author: "%{title}"',
      showTitle: 'Show Author: "%{title}"',
      createTitle: 'Create New Author',
      name: 'Authors',
      aside: {
        title: 'Authors',
        description:
          'An author is someone that can be assigned as the owner/author of specific content in the GG-Admin Panel.',
      },
      fields: {
        id: 'Id',
        username: 'Username',
        firstName: 'First Name',
        lastName: 'Last Name',
        avatar: 'Author Picture',
        user: 'User',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
        publishedAt: 'Published At',
      },
      tabs: {
        summary: 'Summary',
        image: 'Photo',
        socialMedia: 'Social Media',
      },
    },
    territories: {
      itemTitle: 'Territory: "%{title}"',
      editTitle: 'Edit Territory: "%{title}"',
      showTitle: 'Show Territory: "%{title}"',
      createTitle: 'Create New Territory',
      name: 'Territories',
      aside: {
        title: 'Territories',
        description: `A territory is country where Guardian Group's services is available to showcase on the public facing website.`,
      },
      fields: {
        id: 'Id',
        name: 'Name',
        slug: 'Slug',
        alpha2: 'Alpha2',
        phoneAlpha2: 'Phone Alpha2',
        alpha3: 'Alpha3',
        currencyCode: 'Currency Code',
        flag: 'Flag',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
        translationNameId: 'Translation NameId',
        defaultLocale: 'Default Locale',
        content: 'Content',
        privacyPolicy: 'Privacy Policy',
        termsAndConditions: 'Terms And Conditions',
        staticFlagLink: 'Static Flag Link',
        languages: 'Languages',
        defaultLanguage: 'Default Language',
        lobCategories: 'Lob Categories',
        c360StaticVariable: 'C360 Static Variable',
        subTerritories: 'Sub Territories',
        parentTerritory: 'Parent Territory',
        isAvailableOn360: 'IsAvailableOn360',
        isAvailableOnGGWebsite: 'IsAvailableOnGGWebsite',
        isSubTerritory: 'IsSubTerritory',
        isTerritoryGroup: 'IsTerritoryGroup',
        isRegion: 'Is Region',
        isTerritory: 'Is Territory',
        positionNo: 'PositionNo',
        publishedAt: 'PublishedAt',
        additionalSettings: 'Additional Settings',
        cancellationPolicy: 'Cancellation Policy',
        securityPolicy: 'Security Policy',
      },
      tabs: {
        summary: 'Summary',
        details: 'Details',
        image: 'Flag',
        miscellaneous: 'Miscellaneous',
        content: 'content',
        additionalSettings: 'Additional Settings',
      },
    },
    users: {
      itemTitle: 'User: "%{title}"',
      editTitle: 'Edit User: "%{title}"',
      showTitle: 'Show User: "%{title}"',
      createTitle: 'Create New User',
      name: 'Users',
      aside: {
        title: 'Users',
        description: `A user is someone that is able to log into this GG Admin Panel.`,
      },
      fields: {
        id: 'Id',
        username: 'Username',
        firstName: 'First Name',
        lastName: 'Last Name',
        email: ' Email',
        isAuthor: 'Is Author',
        author: 'Author',
        role: 'Role',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
        publishedAt: 'Published At',
        confirmed: 'Confirmed',
        password: 'Password',
      },
      tabs: {
        summary: 'Summary',
        security: 'Security',
      },
    },
    overRides: {
      itemTitle: 'Override: "%{title}"',
      editTitle: 'Edit Override: "%{title}"',
      showTitle: 'Show Override: "%{title}"',
      createTitle: 'Create New Override',
      name: 'Overrides',
      fields: {
        id: 'Id',
        title: 'Title',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
        publishedAt: 'Published At',
        territories: 'Territories',
        description: 'Description',
        coverText: 'Cover Text',
        coverImage: 'Cover Image',
      },
      tabs: {
        summary: 'Summary',
        coverImage: 'Cover Image',
        coverText: 'Cover Text',
      },
    },
    pages: {
      itemTitle: 'Page: "%{title}"',
      editTitle: 'Edit Page: "%{title}"',
      showTitle: 'Show Page: "%{title}"',
      createTitle: 'Create New Page',
      name: 'Pages',
      fields: {
        id: 'Id',
        title: 'Title',
        slug: 'Slug',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
        territories: 'Territories',
        description: 'Description',
        coverText: 'Cover Text',
        coverImage: 'Cover Image',
        defaultLocale: 'Default Locale',
        ggOverrides: 'GG Overrides',
        publishedAt: 'Published At',
        cover: 'Cover',
      },
      tabs: {
        summary: 'Summary',
        coverImage: 'Cover Image',
        coverText: 'Cover Text',
      },
    },
    actionBars: {
      itemTitle: 'ActionBars: "%{title}"',
      editTitle: 'Edit ActionBar: "%{title}"',
      showTitle: 'Show ActionBar: "%{title}"',
      createTitle: 'Create New ActionBar',
      name: 'ActionBars',
      fields: {
        id: 'Id',
        name: 'Name',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
        publishedAt: 'Published At',
        disableActionBar: 'Disable ActionBar',
        bannerActionItems: 'Banner Action Items',
        bannerStaticItem: 'Banner Static Item',
      },
      tabs: {
        summary: 'Summary',
        bannerActionItems: 'Banner Action Items',
        bannerStaticItem: 'Banner Static Item',
      },
    },
    headerSection: {
      itemTitle: 'Header: "%{title}"',
      editTitle: 'Edit Header: "%{title}"',
      showTitle: 'Show Header: "%{title}"',
      createTitle: 'Create New Header',
      name: 'Header',
      fields: {
        id: 'Id',
        title: 'Title',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
        publishedAt: 'Published At',
        description: 'Description',
        links: 'Links',
      },
      tabs: {
        summary: 'Summary',
        links: 'Links',
      },
    },
    headerDropdown: {
      itemTitle: 'Header Dropdown: "%{title}"',
      editTitle: 'Edit Header Dropdown: "%{title}"',
      showTitle: 'Show Header Dropdown: "%{title}"',
      createTitle: 'Create New Header Dropdown',
      name: 'Header Dropdown',
      fields: {
        id: 'Id',
        title: 'Title',
        dropdownLinks: 'Dropdown Links',
        createdAt: 'Created At',
        publishedAt: 'Published At',
      },
      tabs: {
        summary: 'Summary',
      },
    },
    footerSection: {
      itemTitle: 'Footer: "%{title}"',
      editTitle: 'Edit Footer: "%{title}"',
      showTitle: 'Show Footer: "%{title}"',
      createTitle: 'Create New Footer',
      name: 'Footer',
      fields: {
        id: 'Id',
        title: 'Title',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
        publishedAt: 'Published At',
        description: 'Description',
        links: 'Links',
        displayText: 'DisplayText',
        href: 'Href',
        target: 'Target',
        socialMediaLinks: 'SocialMediaLinks',
        linksColumns: 'LinksColumns',
      },
      tabs: {
        summary: 'Summary',
        links: 'Links',
        socialMediaLinks: 'Social Media Links',
        linksColumns: 'LinksColumns',
      },
    },
    shareHolderQuery: {
      itemTitle: 'ShareHolder Query: "%{title}"',
      editTitle: 'Edit ShareHolder Query: "%{title}"',
      showTitle: 'Show ShareHolder Query: "%{title}"',
      createTitle: 'Create New ShareHolder Query',
      name: 'ShareHolder Query',
      fields: {
        id: 'Id',
        title: 'Title',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
        publishedAt: 'Published At',
        description: 'Description',
        slug: 'Slug',
        feedbackFormTitle: 'FeedbackFormTitle',
        defaultLocale: 'Default Locale',
        authors: 'Authors',
        locations: 'Locations',
        company: 'Company',
      },
      tabs: {
        summary: 'Summary',
      },
    },
    productCategories: {
      itemTitle: 'Product Category: "%{title}"',
      editTitle: 'Edit Product Category: "%{title}"',
      showTitle: 'Show Product Category: "%{title}"',
      createTitle: 'Create New Product Category',
      name: 'Product Categories',
      fields: {
        id: 'Id',
        name: 'Name',
        description: 'Description',
        key: 'Key',
        slugCondition: 'Slug Condition',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
        publishedAt: 'Published At',
        slug: 'Slug',
        defaultLocale: 'Default Locale',
      },
      tabs: {
        summary: 'Summary',
      },
    },
    corporateStatement: {
      itemTitle: 'Corporate Statement: "%{title}"',
      editTitle: 'Edit Corporate Statement: "%{title}"',
      showTitle: 'Show Corporate Statement: "%{title}"',
      createTitle: 'Create New Corporate Statement',
      name: 'Corporate Statement',
      fields: {
        id: 'Id',
        title: 'Title',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
        publishedAt: 'Published At',
        slug: 'Slug',
        defaultLocale: 'Default Locale',
        coreValues: 'Core Values',
        description: 'Description',
        mission: 'Mission',
        purpose: 'Purpose',
      },
      tabs: {
        summary: 'Summary',
        content: 'Content',
      },
    },
    coreValues: {
      itemTitle: 'Core Values: "%{title}"',
      editTitle: 'Edit Core Values: "%{title}"',
      showTitle: 'Show Core Values: "%{title}"',
      createTitle: 'Create New Core Values',
      name: 'Core Values',
      fields: {
        id: 'Id',
        title: 'Title',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
        publishedAt: 'Published At',
        slug: 'Slug',
        defaultLocale: 'Default Locale',
        corporateStatement: 'Corporate Statement',
        description: 'Description',
        content: 'Content',
        color: 'Color',
      },
      tabs: {
        summary: 'Summary',
        content: 'Content',
        image: 'Image',
      },
    },
    ggGlossaryItems: {
      itemTitle: 'Glossary Item: "%{title}"',
      editTitle: 'Edit Glossary Item: "%{title}"',
      showTitle: 'View Glossary Item: "%{title}"',
      createTitle: 'Create Glossary Item',
      name: 'Glossary Item',
      aside: {
        title: 'Glossary Items',
        description: 'Glossary Items',
      },
      fields: {
        title: 'Title',
        description: 'Description',
        alphabet: 'Alphabet',
        category: 'Category',
        type: 'Type',
        lob: 'LOB',
        seeAlso: 'See Also',
        createdAt: 'Created At',
        publishedAt: 'Published At',
        updatedAt: 'Updated At',
      },
      tabs: {
        details: 'Details',
      },
    },

    ggGlossary: {
      itemTitle: 'Glossary: "%{title}"',
      editTitle: 'Edit Glossary: "%{title}"',
      showTitle: 'View Glossary: "%{title}"',
      createTitle: 'Create Glossary',
      name: 'Glossary',
      aside: {
        title: 'Glossary',
        description: 'Glossary',
      },
      fields: {
        title: 'Title',
        slug: 'Slug',
        description: 'Description',
        createdAt: 'Created At',
        publishedAt: 'Published At',
        glossary_items: 'Glossary Items',
        gg_companies: 'Companies',
        updatedAt: 'Updated At',
      },
      tabs: {
        details: 'Details',
      },
    },

    wizardListings: {
      itemTitle: 'Wizard Listing: "%{title}"',
      editTitle: 'Edit Wizard Listing: "%{title}"',
      showTitle: 'Show Wizard Listing: "%{title}"',
      createTitle: 'Create New Wizard Listing',
      name: 'Wizard Listings',
      aside: {
        title: 'Wizard Listings',
        description: `A wizard listing is the path to the form for the user fill out details to communicate with Guardian Group.`,
      },
      fields: {
        id: 'Id',
        description: 'Description',
        title: 'Title',
        slug: 'Slug',
        agentSlug: 'Agent Slug',
        wizardId: 'Wizard Id',
        materialIcon: 'Icon',
        defaultLocale: 'Default Locale',
        territories: 'Territories',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
        publishedAt: 'Published At',
      },
      tabs: {
        summary: 'Summary',
      },
    },
    helpArticleCategories: {
      itemTitle: 'Help Article Category: "%{title}"',
      editTitle: 'Help Edit Article Category: "%{title}"',
      showTitle: 'Help Show Article Category: "%{title}"',
      createTitle: 'Create New Help Article Category',
      name: 'Help Article Categories',
      aside: {
        title: 'Help Article Categories',
        description: `An article category is used to group help articles into different areas in the public facing website.`,
      },
      fields: {
        id: 'Id',
        name: 'Name',
        defaultLocale: 'Default Locale',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
        publishedAt: 'Published At',
      },
      tabs: {
        summary: 'Summary',
      },
    },
    helpArticles: {
      itemTitle: 'Help Article: "%{title}"',
      editTitle: 'Edit Help Article: "%{title}"',
      showTitle: 'Show Help Article: "%{title}"',
      createTitle: 'Create New Help Article',
      name: 'Help Articles',
      fields: {
        id: 'Id',
        title: 'Title',
        description: 'Description',
        slug: 'Slug',
        content: 'Content',
        proseMirrorContent: 'Prose Mirror Content',
        featuredImage: 'Featured Image',
        categories: 'Categories',
        territories: 'Territories',
        defaultLocale: 'Default Locale',
        authors: 'Authors',
        publishedAt: 'Published At',
        updatedAt: 'Last Updated At',
        createdAt: 'Created At',
      },
      tabs: {
        content: 'Content',
        summary: 'Summary',
        miscellaneous: 'Miscellaneous',
        featuredImage: 'Featured Image',
      },
    },
  },
};

export default messages;

/*

 categories: {
      name: 'Category |||| Categories',
      fields: {
        products: 'Products',
      },
    },
    comments: {
      name: 'Comment |||| Comments',
      fields: {
        body: 'Body',
        created_at: 'Created at',
        article_id: 'Articles',
        author: {
          name: 'Author',
        },
      },
    },
    reviews: {
      name: 'Review |||| Reviews',
      amount: '1 review |||| %{smart_count} reviews',
      relative_to_poster: 'Review on poster',
      detail: 'Review detail',
      fields: {
        customer_id: 'Customer',
        command_id: 'Order',
        product_id: 'Product',
        date_gte: 'Articleed since',
        date_lte: 'Articleed before',
        date: 'Date',
        comment: 'Comment',
        rating: 'Rating',
      },
      action: {
        accept: 'Accept',
        reject: 'Reject',
      },
      notification: {
        approved_success: 'Review approved',
        approved_error: 'Error: Review not approved',
        rejected_success: 'Review rejected',
        rejected_error: 'Error: Review not rejected',
      },
    },

     article: {
    list: {
      search: 'Search',
    },
    form: {
      summary: 'Summary',
      body: 'Content',
      miscellaneous: 'Miscellaneous',
      comments: 'Comments',
    },
    edit: {
      title: 'Article "%{title}"',
    },
    action: {
      save_and_edit: 'Save and Edit',
      save_and_add: 'Save and Add',
      save_and_show: 'Save and Show',
      save_with_average_note: 'Save with Note',
    },
  },
  comment: {
    list: {
      about: 'About',
      contentType: 'Related Content',
    },
  },
  user: {
    list: {
      search: 'Search',
    },
    form: {
      summary: 'Summary',
      security: 'Security',
    },
    edit: {
      title: 'User "%{title}"',
    },
    action: {
      save_and_add: 'Save and Add',
      save_and_show: 'Save and Show',
    },
  },

*/
