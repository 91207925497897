import React, { useState, forwardRef, useEffect } from 'react';
import SettingsIcon from '@material-ui/icons/Settings';

import {
  Layout,
  AppBar,
  UserMenu,
  // useLocale,
  // useSetLocale,
  MenuItemLink,
  usePermissions,
  useAuthenticated,
} from 'react-admin';
import TreeMenu from '@bb-tech/ra-treemenu';
import {
  makeStyles,
  MenuItem,
  ListItemIcon,
  Typography,
  Drawer,
  Dialog,
  Box,
  Grid,
  Button,
} from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import LanguageIcon from '@material-ui/icons/Language';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PaletteIcon from '@material-ui/icons/Palette';
import LabelIcon from '@material-ui/icons/Label';
import { Link as RouterLink } from 'react-router-dom';
import { LanguageSwitcher, ThemeSwitcher, Brand } from '../common';
import Cookies from '../../utils/functions/cookies';
import { userIdKey, admin, editor, domain } from '../../utils/variables';
import WebsiteButton from './websiteButton';

const useStyles = makeStyles((theme) => ({
  menuItem: {
    color: theme.palette.text.secondary,
  },
  notification: {
    color: theme.palette.getContrastText(theme.palette.secondary.main),
  },
  icon: { minWidth: theme.spacing(5) },
}));

const MyUserMenu = (props) => {
  const { permissions, loaded, error } = usePermissions();

  // console.log('permissions: ', permissions);
  const classes = useStyles();
  const userId = Cookies.getCookie(userIdKey);
  return (
    <UserMenu {...props}>
      <MenuItemLink
        leftIcon={<ThemeSwitcher />}
        to="#"
        tooltipProps={{ title: 'Switch Theme' }}
      />

      <MenuItemLink
        // to="/profile"
        to={`/users/${userId}/show`}
        component={RouterLink}
        primaryText="Profile"
        leftIcon={<LabelIcon />}
      />
      {permissions === admin && (
        <MenuItemLink
          // to="/profile"

          to="/settings"
          component={RouterLink}
          primaryText="Settings"
          leftIcon={<SettingsIcon />}
        />
      )}
    </UserMenu>
  );
};

const Title = ({ className }) => (
  <Box flex="1" px={2} flexDirection="row" justifyContent="flex-start">
    {/* <Typography className={className} variant="h6">
      Guardian Group
    </Typography> */}
    <Typography className={className} variant="body1" id="react-admin-title" />
  </Box>
);

const MyAppBar = (props) => {
  const classes = useStyles();
  return (
    <AppBar {...props} color="default" userMenu={<MyUserMenu {...props} />}>
      <Grid container justify="center" alignItems="center" direction="row" spacing={1}>
        <Grid item md={2}>
          <Brand />
        </Grid>
        <Grid item md={6} align="left">
          <Title />
        </Grid>
        <Grid item md={2.5}>
          <WebsiteButton />
        </Grid>

        <Grid item md={1.5}>
          <LanguageSwitcher />
        </Grid>
        {/* <Grid item md={1}>
          <RouterLink to="/notifications">
            <Badge badgeContent={4} color="primary">
              <NotificationsIcon className={classes.notification} />
            </Badge>
          </RouterLink>
        </Grid> */}
      </Grid>
    </AppBar>
  );
};

const FullLayout = (props) => {
  const [loading, setLoading] = useState(false);

  useAuthenticated(); // redirects to login if not authenticated

  // useEffect(() => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 1000);
  // }, []);

  let treeMenu = TreeMenu;
  if (loading) {
    treeMenu = () => <div>Loading...</div>;
  }
  return <Layout {...props} appBar={MyAppBar} menu={treeMenu} />;
};

export default FullLayout;
/*
      <SwitchLanguageMenuItem />
      <SwitchThemeMenuItem />

            <MenuItemLink
        to="/custom-route"
        primaryText={
          <Badge badgeContent={4} color="primary">
            Notifications
          </Badge>
        }
        onClick={props.onClick}
      />

 <MenuItemLink
        to="/profile"
        component={RouterLink}
        primaryText="Profile"
        leftIcon={<LabelIcon />}
      />

      <MenuItemLink
        // ref={ref}
        className={classes.menuItem}
        onClick={() => {
          setSwitch('language');
          props.onClick();
          console.log('language set');
          // props.onClick();
          // setLocale(locale === 'en' ? 'fr' : 'en');
          // props.onClick();
        }}
      >
        <ListItemIcon className={classes.icon}>
          <LanguageIcon />
        </ListItemIcon>
        Switch Language
      </MenuItemLink>
      <MenuItemLink
        // ref={ref}
        className={classes.menuItem}
        onClick={() => {
          setSwitch('theme');
          props.onClick();
          // props.onClick();
          // setLocale(locale === 'en' ? 'fr' : 'en');
          // props.onClick();
        }}
      >
        <ListItemIcon className={classes.icon}>
          <PaletteIcon />
        </ListItemIcon>
        Switch Theme
      </MenuItemLink>

const SwitchLanguage = forwardRef((props, ref) => {
  //const locale = useLocale();
  //const setLocale = useSetLocale();


  return (
    <MenuItem
      ref={ref}
      className={classes.menuItem}
      onClick={() => {
        setLocale(locale === 'en' ? 'fr' : 'en');
        props.onClick();
      }}
    >
      <ListItemIcon className={classes.icon}>
        <Language />
      </ListItemIcon>
      Switch Language
    </MenuItem>
  );
});
*/
