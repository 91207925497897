import React, { useState, useContext } from 'react';
import { fetchUtils, useLogout, AdminContext } from 'react-admin';

// import { createMuiTheme } from '@material-ui/core/styles';
// import a theme provider which we would apply the theme across all components, and
// the createMuiTheme would create a custom theme for us.
import { getThemeByName } from './themes'; // formatThemeFromForm, themeTypes
import { ThemeContext } from './context';
import { themeStorage } from './storage';

import authProvider from './providers/auth';
import LanguageProvider from './providers/language';
import Cookies from './utils/functions/cookies';
import i18nProvider from './providers/i18n';
import simpleRestProvider from './providers/strapi';
import Routes from './routes';

import { domain, tokenKey, uploadFields } from './utils/variables';

// import fetchJson from "./utils/functions/fetchJson";

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = Cookies.getCookie(tokenKey);
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options).catch((err) => {
    console.log('err: ', JSON.stringify(err));
    console.log('statusCode', err?.body?.status);
    if (err?.body?.statusCode === 401 || err?.status === 401) {
      window.location.replace('/logout?reason=expire');
      return { status: 404, body: {}, json: {} };
      // window.location.href = '/#/logout';
      // || err?.body?.statusCode === 404
    }
    if (err?.status === 404) {
      return { status: 404, body: {}, json: {} };
    }
    throw new Error(err?.message || 'Unexpected error occurred.');
  });
};
// const language = 'nl'//useLocale();

// const Title = ({ className }) => (
// <span className={className}>Guardian Group Admin Panel</span>
// );

// const Ready = () => <div>{(permissions) => getRoutes(permissions, translate)}</div>;

const App = () => {
  // const localProvider = i18nProvider;

  const curThemeValue = useContext(ThemeContext)?.themeValue;

  const [themeValue, setThemeValue] = useState(curThemeValue);

  // console.log('themeValue: ', themeValue);

  // Retrieve the theme object by theme name
  const theme = getThemeByName(themeValue); // themeValue
  // ? createMuiTheme(formatThemeFromForm(themeValue))
  // : getThemeByName(themeTypes.light);
  // console.log('theme: ', theme);
  // debugger;
  // console.log(localProvider.getLocale());

  const addStyle = (url) => {
    const style = document.createElement('link');
    style.href = url;
    style.rel = 'stylesheet';
    style.async = true;
    document.head.appendChild(style);
  };

  React.useEffect(() => {
    const cssUrl = `${domain.strapi}/icons/style.css?v=2`;
    addStyle(cssUrl);
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        themeValue,
        setThemeValue: (value) => {
          setThemeValue(value);
          themeStorage.setTheme(value);
        },
      }}
    >
      <LanguageProvider>
        {(language) => {
          const dataProvider = simpleRestProvider(
            domain?.strapi,
            httpClient,
            uploadFields,
            language,
          );

          return (
            <AdminContext
              authProvider={authProvider}
              dataProvider={dataProvider}
              i18nProvider={i18nProvider}
            >
              <Routes theme={theme} />
            </AdminContext>
          );
        }}
      </LanguageProvider>
    </ThemeContext.Provider>
  );
};

export default App;

/*
          const usersViews =
                  permissions.toLowerCase() === 'administrator' ? users : {};
                const territoryViews =
                  permissions.toLowerCase() === 'administrator' ? territories : {};
                const lobCategoriesViews =
                  permissions.toLowerCase() === 'administrator' ||
                  permissions.toLowerCase() === 'editor'
                    ? lobCategories
                    : {};

            <Resource
              name="gg-comments"
              {...comments}
              options={{ label: 'GG Comments' }}
            />,

 permissions === 'admin'
            ? <Resource name="categories" list={CategoryList} edit={CategoryEdit} icon={CategoryIcon} />
            : null,
    <Resource
        name="gg-comments"
        {...tags}
        options={{ label: "GG Comments" }}
      />,
      <Resource
        name="gg-comments"
        {...comments}
        options={{ label: "GG Comments" }}
      />,
      <Resource name="gg-tags" {...tags} options={{ label: " GG Tags" }} />,
      <Resource name="users" {...users} options={{ label: "Users" }} />,

            <Resource
        name="gg-bookmarks"
        {...bookmarks}
        options={{ label: "GG Bookmarks" }}
      />,
      <Resource
        name="gg-shares"
        {...shares}
        options={{ label: "GG Shares" }}
      />,
      <Resource
        name="gg-favourites"
        {...favourites}
        options={{ label: "GG Favourites" }}
      />,
                <Resource
            name="gg-comments"
            {...comments}
            options={{ label: 'GG Comments' }}
          />,
      */
