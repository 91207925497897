import Loadable from 'react-loadable';
import { Loading } from 'react-admin';
import ListAltIcon from '@material-ui/icons/ListAlt';

const AsyncPageCreate = Loadable({
  loader: () => import('./create'),
  loading: Loading,
});
const AsyncPageEdit = Loadable({
  loader: () => import('./edit'),
  loading: Loading,
});
const AsyncPageList = Loadable({
  loader: () => import('./list'),
  loading: Loading,
});
const AsyncPageShow = Loadable({
  loader: () => import('./show'),
  loading: Loading,
});

export default {
  list: AsyncPageList,
  create: AsyncPageCreate,
  edit: AsyncPageEdit,
  show: AsyncPageShow,
  icon: ListAltIcon,
};
